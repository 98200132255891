
.rbg{
    background: linear-gradient(to bottom,#FFFFFF,#C9E0FF    );
}


  body {
    /* margin: 0;
    padding: 0; */
    overflow-x: hidden; /* This prevents horizontal scrolling */
  }
  .container {
    width: 100%;
    max-width: 100vw; 
  }
 
  body, html, .rbg {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }