.wform-container {
    /* background-image: url(webbg.png); */
    background-size: cover;
    width: 100%;
    height: 2500px;
    position: relative; /* Position relative to contain absolute positioned overlay */
  }
  .awform-container {
    /* background-image: url(webbg.png); */
    background-size: cover;
    width: 100%;
    height: 1500px;
    position: relative; /* Position relative to contain absolute positioned overlay */
  }
  .dform-container{
    background-size: cover;
    width: 100%;
    position: relative;
    padding-top: 8%;
  }
  .adoclay{
    padding: 2rem;
  }
  .wfoverlay {
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 80%;
    border-radius: 7px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
    backdrop-filter: blur(10px); /* Apply blur effect */
  
  }
.wfimg:hover{
  transform: scale(1.1); /* Adjust the scaling factor as needed */

}
.mwfimg:hover{
  transform: scale(1.1); /* Adjust the scaling factor as needed */

}
.wfimg{
  width: 80%;
}
  .wform {
    margin-top: 4%;
  }
  
  .wform-chat-container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 335px;
    justify-content: space-between;
    padding: 3%;
    width: 95%;
    margin-left: 2rem;
  }
  .mwfimg {
    width: 80%;
    margin-left: 8rem;
}
  .mi{
width: 50%;  
  }
  .mm{
    padding-left: 1rem;
    color: #193054;
    width: 55%;
    height: auto;
    /* text-align: center; */
    font-family: "Fira Sans", sans-serif;


  }
  .chat-p{
    line-height: 1.8;
    margin-top: 1rem !important;  }
    
  .chat-message {
    color: #193054;
    width: 680px;
    height: auto;
    text-align: right;
    font-family: "Fira Sans", sans-serif;

  }
  .dchat-message {
    color: #193054;
    width: 50%;
    height: auto;
    text-align: right;
    font-family: "Fira Sans", sans-serif;

  }
  .mchat-message {
    color: #193054;
    width: 100%;
    height: auto;
    text-align: left;
    font-family: "Fira Sans", sans-serif;

  }
  .mlchat-message {
    color: #193054;
    width: 100%;
    height: auto;
    text-align: right;
    font-family: "Fira Sans", sans-serif;

  }
  
  .chat-image {
    /* height: 400px;
    padding-left: 35px; */
    width: 50%;
  }
  .wchat-image {
    
    width: 80%;
  }
  .wmi{
    width: 80%;

  }
  .wflay {
    background-color: #8cb9f5;
    width: 100%;
    height: 650px;
  }
  
  .wform-divider {
   padding: 6%;
  }
  
    
    /* .wform-chat-container {
      width: 100%;
      height: 397px;
      background-color: #193054;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      box-sizing: border-box;
    }
    
    .chat-message {
      color: white;
      width: 600px;
      height: auto;
    }
    
    .chat-image {
       width: 534px; 
      height: 400px;
    }
    .fL{
      margin-top: -128px;
    }
    .wflay{
      background-color: #C9E0FF    ;
      width: 100%;
      height: 650px;
    }
     */
     