.are {
    font-size: 49px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    color: #6F7174;
  }
  .slick-prev:before, .slick-next:before {
   
    color: #193054;
    
    
}
  
  @keyframes slideIn {
    from {
      transform: translateX(-100%); /* Start off-screen to the left */
      opacity: 0; /* Start transparent */
    }
    to {
      transform: translateX(0); /* End at its original position */
      opacity: 1; /* End fully visible */
    }
  }
  
  .arpg {
    animation: slideIn 1s ease forwards; /* Apply the animation */
  }
  
  .btnare{
    background: linear-gradient(to bottom, #154992, #193054  );
    font-family: "Blinker", sans-serif;
    padding: .8rem;
    font-size: 16px;
    font-weight: bold;
  }
  
  .head {
    font-size: 62px;
    color: #193054;
    font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-style: normal;  }
    .pead {
      /* font-size: 62px; */
      color: black;
      font-family: "Blinker", sans-serif;
      font-weight: 700;
      font-style: normal;  }
      .prpara{
        color: black;
        font-family: "Fira Sans", sans-serif;
      }
  .arpg {
    width: 340px;
    height: 70px;
    margin-left: 14px;
  }
  .para1 {
    font-size: 16px;
    font-family: "Fira Sans", sans-serif;
    font-style: normal;
  }
  
  .logo-container {
    display: inline-flex; /* or inline-block */
    align-items: center; /* Align items vertically */
  }
  .clogo-container {
    display: inline-flex; /* or inline-block */
  }
  
  .logo {
    width: 16.33px; /* Adjust width as needed */
    height: 23.33px; /* Adjust height as needed */
    margin-right: 10px; /* Add spacing between the logo and text */
  }
  
  .card{
    background-color: transparent;
  }
  .are {
    margin: 0; /* Reset margin for the text */
  }
  
  #video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }
  
  #video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  

  .rb{
    font-family: "Blinker", sans-serif;
    font-weight: 400;    
    font-style: normal;
    font-size: 18px;

  }

  .rbpg{
    width: 85%;
    max-width: 100%;
  }
  
  /* customer jsx */
   .brd{
    border: none !important;
   }
  
  /* exb.jsx */
  .db{
    background-color:#d0e2fa ;
    padding-top: 0px;
    /* padding-bottom: 90px; */
  }
  .emptylay{
    background-color: #D9D9D9;
    width: 100%;
    height: 369px;
  }
  
  @media (max-width: 768px) {
    .full{
      text-align: center;
      margin-top: -2.5rem;
    }
    .frame{
      padding: 0 !important;
    }
    .head{
      font-size: 28px;
      /* padding-top: 10px; */
    }
    .are{
      font-size: 18px;
    }
    .para1{
      padding-top: 1.5rem !important;
      font-size: 16px;
        line-height: 24px;
    }
    .cus{ 
      margin-top: -2em !important;
    }
    .cr{
      margin-top: 20px ;
  
    }
    .arpg {
      width: 160px;
      height: 32px;
      margin-left: 14px;
  }
  .rbpg{
    width: 343px;
  }
  .mic {
    display: inline-flex; /* or inline-block */
    align-items:normal !important; /* Align items vertically */
  }
  .mare{
    /* width: 22rem;
    margin-left: -49px; */
  }
  }
  @media (max-width: 400px) {
    .arpg {
      width: 140px;
      height: 30px;
      margin-left: 3px;
  }

  .mare{
    width: 22rem;
    /* margin-left: -4rem; */
  }
 
  }

  /* @media (max-width: 360px) {
    .mar{
      margin-left: 1.9rem !important;
    }
  } */

  .rbpg {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  /* home.css */
.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
}
