/* Trusted.css */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.mtrusted-sec-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center align the items */
  margin-top: 0;
}
.mtbody {
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.trusted-img-wrapper {
  flex: 1 1 calc(33.33% - 30px); /* Set a flexible base size for three columns */
  margin: 15px;
  max-width: calc(33.33% - 30px); /* Ensure the max-width doesn't exceed the 3-column layout */
}

.mtrusted-img {
  width: 100%;
    height: 75px;
    object-fit: contain;
    padding: 5px;
}



