/* Trusted.css */

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .trusted-sec-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 -10px; /* Negative margin to counteract the default margin of col-md-2 */

  }
  
  .trusted-img {
    height: 62px; 
    width: 144px;
    margin: 20px; 
  }

  .trcont{
    margin-left: 6rem !important;
  }
  
  .tbody {
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    margin: 1rem; /* Add margin to create space between each column */
  }