.features {
    margin-left: .508em;
    margin-right: .508em;
    margin-top: 1.2em;
    margin-bottom: 6.5em;
    font-size: 16px;
    color: #ffff;
    font-family: "Fira Sans", sans-serif;
}
.pfeatures {
    margin-left: .508em;
    margin-right: .508em;
    margin-top: 1.2em;
    margin-bottom: 6.5em;
    font-size: 16px;
    color:#193054    ;
    font-family: "Fira Sans", sans-serif;
}
/* features.css */

.mobile-app-icon {
    width: 70%;
    margin: 0 auto;
    display: block; /* Centers image within the div */
  }
  
.rb{
    width: 77% !important;
    margin-left: 12% !important;
    
}
.rbb{
    width: 80% !important;
    margin-left: 11% !important;
    background: linear-gradient(to bottom,#FFFFFF,#C9E0FF    );

}
.rbimg{
    width: 85%;
    height: 600px;
    margin-left: 7.5%;
    padding-bottom: 40px;
    padding-top: 40px;
}
/* Initially hide elements */
.row.sb {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  /* When the element becomes visible */
  .row.sb.transitioned {
    opacity: 1;
    transform: translateY(0);
  }
  
.btxt{
   color:white;
}
.da{
    margin-top: 25px;
}

.t-head {
    text-align: center;
    font-size: 18px;
    font-family: "Fira Sans", sans-serif;
}
.mobile-app-img {
    width: 70% !important;
    height: auto; /* Maintain aspect ratio */
    /* Add any additional styles you want here */
  }
  
.fmh {
    padding-bottom: 1.5rem;
    font-size: 50px;
    font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-style: normal;
    padding-left: 8rem;
    width: 93%;
    margin-left: -5%;
}
/* .mainH h1 {
    width: 848px;
    margin-left: 116px;
} */

/* .mainH h1:contains('Low-Code Reporting tools for Modern Businesses to accelerate decision making') {
    width: 965px;
    margin-left: 116px;
} */
.fcloud {
    width: 111.56px;
    height: 89.2px;
}

.overlay-box.active {
    border-bottom: 7px solid #79C977; /* Change border color for the active overlay */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: border-top-color 0.3s ease; /* Add transition for smooth effect */
  }
  

.deploy {
    width: 67.79px;
    height: 62.64px;
}
.pdeploy {
    width: 55.79px;
    height: 55.64px;
}
.mpdeploy {
    width: 34.79px;
    height: 55.64px;
}
.rdeploy {
    width: 64.79px;
    height: 55.64px;
}

.coins {
    width: 58.15px;
    height: 54.98px;
}

.lock {
    width: 51.49px;
    height: 67.58px;
}

.low {
    width: 64.36px;
    height: 51.49px;
}

.flight {
    width: 51.8px;
    height: 54.53px;
}

.overlay {
    position: relative;
    bottom: 27px;
    top: 0px;
    left: 0px;
    width: 100%;
    /* height: 394px; */
    background: linear-gradient(#4D86D6 100%, #B4D2FC 100%);
    border-top-right-radius: 95px;
    border-top-left-radius: 95px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

}
    

.overlays {
    /* position: absolute;
    transform: translateY(-50%); */
    z-index: 2;
    display: flex;
    justify-content: space-around;
    width: 85%;
    margin-left: 7%;
    /* padding-inline: 8%; */
    left: 7%;
    right: 7%;
    
}

.over {
    margin-left: 0;
    display: flex;
    justify-content: space-around;

 }

.fcloud1 {
    /* width: 44px; */
    height: 44px;
    margin-top: 45px;
    transition: filter 0.3s ease, border 0.3s ease; /* Smooth transition for the hover effect */


}
.rcloud1 {
    /* width: 44px; */
    height: 44px;
    margin-top: 45px;
    /* filter: brightness(0) saturate(100%); */

}
.hover-container:hover .ot{
    color: #B6D4FF;
  }
  
  .hover-container:hover .fcloud1 {
    filter: brightness(0) saturate(100%) invert(82%) sepia(21%) saturate(443%) hue-rotate(168deg) brightness(95%) contrast(95%);
    }

.bg {
    position: relative;
    background: linear-gradient(to right, #154992, #193054  );
    height: 100%;
    z-index: 0;
    padding-bottom: 10%;
}
.feic {
    font-size: 16px;
    font-family: arial;
    margin-top: 19px;
}
.fbg{
    margin-top: -136px;
}
.flogo{

        width: 23.33px;
        height: 23.33px;
        margin-right: 10px;
    }


@media (max-width: 768px) {
    .center {
        text-align: center;
        margin-top: -3rem    }

    .col-md-4 {
        flex: 100%; 
        max-width: 100%; 
        margin-bottom: 20px; 
    }

    .fcloud1 {
        width: 80px; 
        height: auto; 
    }
    
    .mainB{
        text-align: center;
    }
    .mainH{
        text-align: center;
        padding-left: 16px !important;
    }
    .mainimg{
        width: 380px !important ;
        margin-left: 5px !important;
        height: 320px !important;
    }

    .overlay-box{
        width: 476px !important;
        height: 170px !important;
    }

    .row-new.transitioned{
        margin-bottom: 0 !important;
        margin-top: .5em !important;    }
        .bg{
            padding: 0 !important;
        }
}

.content{
    padding-top: 60px;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;

}
.mainH{
    padding-left: 66px;
}
.sub{
    font-size: 16px;
    font-family: "Fira Sans", sans-serif;
    font-style: normal;
    
}

.ex{
    background-color: #FFFFFF;
    padding: 13px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4rem;
    font-family: "Fira Sans", sans-serif;

}
.ex:hover{
background: linear-gradient(to top, #DCE9FD , #C8DDFF );
color: black;

}

.mainB{
    padding-top: 3px;
    padding-left: 20%;
}
.mainimg{
    width: 93%;
    /* margin-left: 3.5%; */
    height:726px    ;
    padding-bottom: 40px;
    padding-top: 40px;

}
.rmainimg{
  
        width: 85%;
        margin-left: 7.5%;
        padding-bottom: 40px;
        padding-top: 40px;

}

.overlay-box {
    /* background: linear-gradient(0deg, #D4E4FC, #E5F0FF, #FFFFFF); */
    /* border-radius: 5px; */
    /* border-top-left-radius: 5px; */
    width: 200px;
    height: 150px;
}

/* .overlay-box:hover {
    border-top: 7px solid ;
    border-top-color: #79C977; 
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: border-top-color 0.3s ease; 


} */
/* Default icon style */
.overlay-box img {
    transition: filter 0.3s ease;
  }
  
  /* Change icon color on hover */
  /* .overlay-box:hover img {
    filter: brightness(0) saturate(100%) invert(82%) sepia(21%) saturate(443%) hue-rotate(168deg) brightness(95%) contrast(95%);
  }
  .ot:hover{
color: #B6D4FF;
  } */
  
.overlay-link {
    display: block;
    height: 100%;
}
.ot{
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin-top: 10px;
    color: white;
}
.rot{
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin-top: 10px;
    color: #193054;
}
.overlay-link.active .underline {
    background-color: #c7dac7;
    border-radius: 0.625em;
    width: 2.5em;
    height: 0.3125em;
    margin: 12px 0 45px 0;
}
.overlay-link.active .overlay-box {
    /* Add styles for the active overlay box */
    border-top: 7px solid #79C977; /* Change border color for the active overlay */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: border-top-color 0.3s ease; /* Add transition for smooth effect */
}


.row-new {
    filter:opacity(0); 
    transition: filter 4s ease; 
}


.row-new.transitioned {
    filter: opacity(1);
    transition: filter 3s ease;
  }
 

  .rdiv {
    margin-left: 7%;
    border: 2px solid white;
    border-radius: 2%;
    width: 85%;
    padding-bottom: 3%;
}
 
 