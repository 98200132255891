.cmg{
    border: none !important;
    background-color: white;
    border-radius:12px
}
.bgc{
    background-color:rgb(183, 228, 247);
    /* margin-top: -12px;
    padding-top: 150px; */
}
.actb:hover{
  background: #193054;
  color: #ffffff;
  
  }
  .abmg11{
   padding-top: 2rem;
  }
.avd{

padding-top: 1rem !important;
padding-bottom: 1rem !important;
}
.custom-carousel .carousel-indicators {
  margin-bottom: -3rem;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}


.cvdo{
  width: 18rem;
  /* margin-left: -12px; */
}
.rf{
    /* padding-left: 33px; */
    padding-bottom: 80px;

}
.vblp {
  font-family: "Fira Sans", sans-serif !important;
  font-weight: 700;
  font-size: 19px;
  font-style: normal;
  margin-left: 12px;
  color: #193054;
}
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mvideo-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 106%;
  height: 100%;
}
.card {
  position: relative;


}
.wf{
  background: linear-gradient(to bottom, #154992, #193054  );

}
.mwf{
  height: 700px;

}

.fr{
  width: 288px !important;
  height: 223px !important;
}
.vmfr{
  width: 100% !important;
  height: 325px !important;
}

.close-button {
  position: absolute;
  top: -8px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
}
.mclose-button {
  position: absolute;
  top: -11px;
  right: 7px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
}

.close-button:hover {
  color: red;
}

.Abctbg {
    height: 450px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }
  
  .Abcontent {
    display: flex;
    
    height: 100%;
  }
  
  .left {
   padding-top: 85px;
    padding-right: 20px;
    width: 130%;
  }
  
  .righta {
    width: 100%;
    padding: 20px;
  }

  .actp {
    font-family: "Fira Sans", sans-serif;
  }

.acth{
    font-size: 62px;
    font-family: "Blinker", sans-serif;
    font-weight: 700;
}
.actb {
  background: linear-gradient(to left, #DCE9FD 0%, #C8DDFF 80%);
  border-radius: 4rem;
  color: #193054;
  padding: 15px 20px;
  border: none;
  font-family: "Fira Sans", sans-serif;
  font-style: normal;
  font-size: 16px;
  cursor: pointer;

} 
.abmg1{
  padding-top: 27px;
}
  .ctb1{
    margin-top: -3rem;
  }
.abb{
  
  text-align: right;
  font-weight: 600;
  align-self: flex-start;
}

.abvh{margin-top: -90px;
  margin-left: 15px;
}  


@media only screen and (max-width: 768px) {

.bgc {
 
  padding-top: 82px;
}
.cvdo{
  margin-left: 0;
  width:100%;
}
.rf {
  padding-left: 7px;
}
}





