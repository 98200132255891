.mobp{
    padding: 35px;
   margin-bottom: -40px;
 }
 .mhead{
  font-size: 44px;
    color: #193054;
    font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-style: normal;
 }
 .fctc{
  padding-top: 8rem;
    margin-left: 5rem;
 }
 .rm{
  margin-left: -25px;
 }
 .mbc{
     padding: 60px;
     border: none;
     background-color: white;
     text-align: center;
 }
 .mimg{
    margin-top: 25px !important;
 }
 .mobg{
  width: 100%;
   object-fit: contain;

 }
 .mog{
  width: 100%;
    overflow: hidden;
 }
 .mbcc{
     margin-top: -130px;
 }
 
 .mblay {
     background-color: #ffff;
     width: 100%;
     height: 656px;
     margin-top: 100px;
     margin-bottom: 100px;
 }
 
 .mctc {
     padding-top: 130px;
     position: relative;
 }
 
 .mctc::before {
     content: '';
     position: absolute;
     top: 69px;
     left: 63px;
     width: 91%;
     height: 100%;
     border-radius: 8px;
     background-color: rgba(128, 128, 128, 0.5);
     backdrop-filter: blur(10px); /* Apply blur effect */
     z-index: 1;
 }
 
 .mctp {
     margin-left: 200px;
     position: relative; /* Ensure text content is on top of the overlay */
     z-index: 2; /* Ensure text content is on top of the overlay */
 }
 
 .mcth {
     margin-left: 200px;
     font-size: 62px;
     position: relative; /* Ensure text content is on top of the overlay */
     z-index: 2; /* Ensure text content is on top of the overlay */
 }
 
 .mctb {
     margin-left: 200px;
     align-items: center;
     justify-content: center;
     background: linear-gradient(to right, #193054 0%, #21457D 80%);
     border-radius: 5px;
     color: white;
     padding: 7px 5px;
     border: none;
     font-size: 16px;
     cursor: pointer;
     position: relative; /* Ensure button is on top of the overlay */
     z-index: 2; /* Ensure button is on top of the overlay */
 }
 
 .mctbg {
     height: 500px;
     background-position: center;
     background-repeat: no-repeat;
     background-color: black;
     background-size: cover;
     position: relative;
     overflow: hidden;
 }
 


 /* mmessges */

 .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px; /* Adjust spacing between rows */
  }
  .mam{
    background-image: linear-gradient(to right, #ffffff 15%, #91a6ff 74%);

  }
  .image-col {
    flex: 0 0 50%; /* Set image column width to 50% */
    padding-right: 10px; /* Add some space between image and text */
  }
  
  .text-col {
    flex: 0 0 50%; /* Set text column width to 50% */
    padding-left: 10px; /* Add some space between text and image */
  }
  
  @media (max-width: 768px) {
    .flex-row {
      flex-direction: row; /* Stack columns vertically on smaller screens */
    }
  
    .img-fluid {
        width: 85%;
        height: auto;
        margin-left: -.5rem;
        margin-top: -1rem;
    }
    .mab{
        width: 119%;
    margin-left: -0.9rem;
    line-height: 1.9;
    font-family: "Fira Sans", sans-serif;

    }
    .mcntr{
      padding: 0 !important;
      line-height: 2;
    }
    .mhead{
      font-size: 28px;
    }
    .mmapp{
      text-align: center;
    }
    .map1{
      line-height: 2;
    }
    .mmbchat{
    margin-top: 0 !important;
    }
    .mmbchati{
      margin: 0 !important;
      padding-top: 0 !important;
      }
      
  }
  

  .flex{
    display: flex;
    justify-content: space-around !important;
  }