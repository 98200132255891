.aboutus-header {
    min-height: 480px;
    width: 100%;
    font-family: alata;
    font-weight: 300;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .aboutoverlay {
    min-height: 480px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    background-color: rgba(0, 0, 2, 0.6);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffff;
  }
  
  
  .al_bottom{
    padding-top: 99px;

  }
  .ah{
    font-size: 62px;
    font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .apara{
    font-size: 16px;
    font-family: "Fira Sans", sans-serif
  }
  .abcontent{
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif ;
    padding-top: 46px;

  }

  /* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .aboutus-header {
    min-height: unset;
    height: 25vh;
    background-size: 110% 100%;
}

  .aboutoverlay {
    min-height: unset;
    height: 25vh;
    width: 100%; /* Set width to 100% for mobile devices */
  }
  .ah{
    font-size: 25px;
  }
  .al_bottom{
    padding-top: 55px;

  }
  .abcontent{
    padding-top: 0;
  }

}