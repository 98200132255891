.body {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gallery {
  width: 100%;
}

.gallery-container {
  align-items: center;
  display: flex;
  height: 400px;
  margin: 0;
  margin-left: 60px;
  margin-top: 100px;
  max-width: 1000px;
  position: relative;
}

.gallery-item {
  height: 454px;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 543px;
  z-index: 0;
  border-radius: 15px ;
  background-size: contain;
}
.right-box {
  width: 954.25px;
    height: 529px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background-color: #193054;
  transition: border-left-color 0.3s ease;
  color:white 
}
.bxcontent {
  width: 81%;
  height: 100%;
  margin-top: 11px;
  color: white;
}

.gallery-item-1 {
  left: 28%;
  opacity: .4;
  border-radius: 15px ;
  transform: translatex(-50%);
}

.gallery-item-2,
.gallery-item-4 {
  height: 474px;
  opacity: 0.8;
  width: 887px;
  z-index: 1;
}

.gallery-item-2 {
  left: 50%;
  transform: translateX(-50%);
}

.bximg{
  margin-top: -54px;
  margin-left: 330px;
  /* width: 366px;
  height: 210px; */
}

.gallery-item-3 {
  box-shadow: -2px 5px 33px 6px rgba(0, 0, 0, 0.35);
  height: 500px;
  opacity: 1;
  left: 60%;
  transform: translateX(-50%);
  width: 954px;
  z-index: 2;
}
.right-box {
  /* Your existing styles */
  position: relative;
}

.gallery-controls-wrapper {
  position: absolute;
  top: 50%;
  right: 20px; /* Adjust this value as needed */
  transform: translateY(-50%);
}


.gallery-item-4 {
  left: 64%;
  transform: translateX(-50%);
}

.gallery-item-5 {
  opacity: .4;
  left: 69%;
  width: 887px;
  transform: translateX(-50%);
}

.gallery-controls {
  display: flex;
  justify-content: center;
  margin: 25px 0;                       
  height: 100px;
}
.gallery-controls button {
  background-color: transparent;   
  border: 0;
  cursor: pointer;
  font-size: 30px;
  padding: 0 12px;
  text-transform: capitalize;
}

.main-heading {
  font-size: 62px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: white;
}
.gallery-controls button:focus {
  outline: none;
}

.gallery-controls-previous {
  position: relative;
}

.gallery-controls-previous::before {
  border: solid white;
  border-width: 0 5px 5px 0;
  content: '';
  display: inline-block;
  height: 5px;
  left: -30px;
  padding: 10px;
  position: absolute;
  top: 30%;
  transform: rotate(135deg) translateY(-50%);
  transition: left 0.15s ease-in-out;
  width: 5px;
}

.gallery-controls-previous:hover::before {
  left: -40px;
}

.gallery-controls-next {
  position: relative;
  margin-left: 790px;
}

.gallery-controls-next::before {
  border: solid white;
  border-width: 0 5px 5px 0;
  content: '';
  display: inline-block;
  height: 5px;
  padding: 10px;
  position: absolute;
  right: -30%;
  top: 49%; /* corrected typo, it was top: 45; */
  transform: rotate(-45deg) translateY(-50%);
  transition: right 0.15s ease-in-out;
  width: 5px;
}

.gallery-controls-next:hover::before {
  right: -40px;
}

.gallery-nav {
  bottom: -15px;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  position: absolute;
  width: 100%;
}

.gallery-nav-li {
  background: #ccc;
  border-radius: 50%;
  height: 10px;
  margin: 0 16px;
  width: 10px;
}

.gallery-nav-li.gallery-item-selected {
  background: #555;
}
.gallery {
  position: relative;
}

.gallery-container {
  position: relative;
  z-index: 1; /* Ensure the gallery container is above the gallery controls */
  padding-top: 100px;
}

.gallery-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2; 
  left: 240px;
}

.bbtn {
  background: white;
  border-radius: 5px;
  padding: 15px 35px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
  
}
.bxbtn {
  margin-top: 40px;
  
}

@media (max-width: 768px) {
  .gallery-item-1{
    left: 70%;
  }
  .right-box{
    width: 360.25px;
  }
  .gallery-item-2{
    left: 124%;
  }
  .gallery-item-3{
    left: 32%;
    width: 305px;
  }
  .gallery-item-4{
    width: 779px;
    left: 107%;
  }
  .gallery-item-5{
    left: 44%;
    width: 381px;
  }
  .main-heading{
    font-size: 35px;
  }
  .bxbtn{
    margin-top: 40px;
  }
  .bximg{
    margin-top: -54px;
    margin-left: 133px;
  }
  .bximg img{
    width: 100px;
  }
  .gallery-controls-next{
    margin-left: 265px;
  }
  .gallery-controls{
    margin-left: -201px;
  }
}