.carousel-card {
    border: none;
}

.rwf {
    height: 400px;
}
.mcc{
  text-align: center;
}
.ctmh{
    line-height: 2;
}
.carousel-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between the title and buttons */
    margin-bottom: -1rem; /* Add some space below the title */
  }
  
  .left-arrow, .right-arrow {
    background: transparent;
    border: none;
    color: #193054; /* Change to desired color */
    /* font-size: 24px;  */
  }
  


/* mform.css */
.dwbg {
    border: none; /* Light border */
    padding: 0; /* Optional: add some padding */
    margin-top: -1rem;
  }
  .adwbg {
    background: rgba(255, 255, 255, 0.2); /* White background with transparency */
    border: 1px solid rgba(255, 255, 255, 0.5); /* Light border */
    border-radius: 15px; /* Rounded corners */
    backdrop-filter: blur(10px); /* Glass effect */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .dmwbmg {
    border-radius: 10px; /* Rounded corners for images */
  }
  
.arrow-btn {
    background: none;
    border: none;
    margin-top: -5%;
    color: white;    /* White color for angle brackets */
    cursor: pointer;
  }
 
  
  .arrow-btn:hover {
    color: #f8f9fa; /* Slight hover effect */
  }
  
.mfcrsl {
    /* height: 700px;  */
    margin-top: -2.2rem !important;
    position: relative;
    background-color: #E5F0FF    ;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    /* width: 114%; */
    /* margin-left: -7%; */
    /* border-radius: 8px; */
}
.mfclogo {
    margin-top: -3rem;
    margin-left: 73px;
    width: 24px;
    height: 24px;
}
.mfuc {
    margin-left: -50px;
    font-weight: 700;
    font-size: 16px;
    margin-top: -1.3rem;}

.custom-carousel .carousel-item.active .card{
    border-top: 7px solid;
    border-top-color: #79C977;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}
.custom-carousel .carousel-inner {
   
    border-radius: 8px;
}
  
.cbm{
    margin-left: 11%;
    margin-top: 1rem
}
.wcbm{
    margin-left: 4%;
    margin-top: 1rem
}
.wfcbm{
    margin-left: 4%;
}

.carousel-item {
    height: 100%; /* Ensure each carousel item takes the full height */
}
.cit{
    color: white;

}
 .carousel-content{
    margin-left: 1.5rem }
.carousel-control-next,
.carousel-control-prev {
    opacity: 1.5;
    position: absolute;
    top: 10%;
    transform: translateY(-50%);
    z-index: 1000;
}

.carousel-control-next-icon {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid black; 
    display: none;
}

.carousel-control-prev-icon {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid black; 
    display: none;

}

.mmgf {
    width: 100%;
    max-width: 350px;
    margin-top: 1rem;
    margin-left: 5px;
    padding-bottom: 8rem;

}
.mmg {
    width: 100%; /* Adjust the width to 100% */
    max-width: 350px; /* Set a maximum width if needed */
    /* margin-left: -10px; */
    padding-bottom: 3rem;
}

.carousel-item .carousel-card {
    border-top: 7px solid;
    border-top-color: #79C977; 
    
    transition: border-top-color 0.3s ease; }

/* .mf{
    border-top: 8px solid;
    border-top-color: #79C977;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: border-top-color 0.3s ease;
} */
 .mf{
    padding: 0 !important;
 }
.slide{
    margin-top: -1.2rem;
}
.icon {
    margin-left: 40%;
    margin-top: -7%;
        width: 24px;
}
.dicon {
    margin-left: 60%;
    margin-top: 28%;
        width: 24px;
}
.aicon {
    margin-left: 59%;
    margin-top: 20%;   

}
.rbicon {
    margin-left: 0;
    margin-top: -7%;
    width: 24px;
}
.ricon {
    margin-left: 59%;
    margin-top: 20%;   

}

.mric{
    width: 20px;
    margin-top: 21%;
}

.mch {
    margin-left: -55%;
    font-family: "Blinker", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: white;
    font-style: normal;
}
.rch {
    /* margin-left: -55%; */
    font-family: "Blinker", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: white;
    font-style: normal;
}
 
.mfcrsl .icon, .mfcrsl .ricon {
    filter: brightness(0) invert(1); /* Makes the icons white */
  }
  
.mmh{
    font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: white;
}
.mmp{
    font-size: 16px;
    color: white;
    font-family: "Fira Sans", sans-serif;
}
.mw{
    font-size: 18px;
    font-family: "Fira Sans", sans-serif;
    padding-top: 1rem;
}
.dmw{
    font-size: 18px;
    font-family: "Fira Sans", sans-serif;
    /* padding-top: 1rem; */
}
.amw{
    font-size: 18px;
    font-family: "Fira Sans", sans-serif;
    padding-top: 1rem;
    line-height: 2;
}
.wm{
    font-size: 18px;
    font-family: "Fira Sans", sans-serif;
    padding-top: 3rem;
}

.mbtn{
    margin-left: -9rem;
    border-radius: 2rem;
}
.mfbtn{
    
    margin-left: -10rem;
    border-radius: 25px;
    padding: 8px 20px;
    margin-top: 1rem;
}

.mbd{
    padding-bottom: 15px;
}


/* M carousel */

.mbg{
    background-color: #193054;
    height: 700px;
    }
    .mfbg{
background-color: #E5F0FF;
border: none;        }    
.wbg {
    background-color:#DDEAFF;
    color: black;
    border-radius: 0;
    border: none;
    height: 800px;
}

.mbmg{
    width: 325px;
    /* height: 112% !important; */
    margin-left: -31px;
    margin-top: 10%;
    padding-bottom: 5rem;
}
.mcmg{
    width: 100%;
    /* height: 112% !important; */
    /* margin-left: -31px; */
    margin-top: 10%;
    padding-bottom: 5rem;
}
.mwbmg{
    width: 100%;
    /* height: 112% !important; */
    margin-left: -1rem;
    margin-top: 2%;
    padding-bottom: 5rem;
}

.mflogo{
    margin-top: -18px;
    margin-left: 6rem;
}

.muc{
    margin-left: -100%;
}

.mmainh{
    margin-top: .5rem;
    font-family: "Blinker", sans-serif;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
}
.wmainh{
    margin-top: .5rem;
    font-family: "Blinker", sans-serif;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    font-style: normal;
}

.custom-carousel .carousel-control-next-icon {
    /* Your custom styles for the next arrow */
    border-left-color:#ffff; /* Change the color to red as an example */
    margin-top: -5rem !important;
    margin-left: 32px !important; 
        display: none !important;

 }
  
  .custom-carousel .carousel-control-prev-icon {
    /* Your custom styles for the previous arrow */
    border-right-color: #ffff; /* Change the color to red as an example */
    margin-top: -5rem !important;
    margin-left: -37px  ;
        display: none !important;

}
@media (max-width: 500px) and (min-width: 400px) {
    .mmg {
        width: 100%;
        max-width: 427px;
        margin-left: 0px;
        padding-bottom: 2rem;
    }
    
}

.icon-text-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Horizontally centers the icon and text */
    gap: 8px; /* Adjusts space between icon and text */
    text-align: center;
    width: 150px;
}

.icon-text-container .icon {
    width: 24px; /* Adjust icon size as needed */
    height: 24px;
}

    .mobile-image-style {
        width: 100%;
        max-width: 300px;
        margin-top: 1rem;
        align-self: center;
        padding-bottom: 8rem;
    
      }