.platform-header {
    min-height: 535px;
    width: 100%;
    font-family: alata;
    font-weight: 300;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .white-logo {
    filter: invert(1);
  }
  
  .ploutoverlay {
    min-height: 535px;
    width: 100%;
    font-family: "Fira Sans", sans-serif;
    font-weight: 300;
    background-color: rgba(0, 0, 2, 0.6);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffff;
  }
  /* .pw{
    color: white !important;
  } */
  .plf {
    width: 70%;
    height: 300px;
    font-family: alata;
    font-weight: 350;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 11rem;
    margin-top: 6%;
  }
  .prleft{
    margin-left: 20%;
    max-width: 75%;
    height: auto;
    color: #193054;
    line-height: 2;
    font-family: "Fira Sans", sans-serif;
}
  .prright{
    max-width: 75%;
    height: auto;
    margin-top: -8%;
    margin-left: 9%;
  }
  
  .ps{
    margin-top: -4rem
  }
  .zoomed {
    transform: scale(1.1); /* Adjust the scaling factor as needed */
  }
  .psrow{
  
    margin-top: 5%;
    
  }
  .prrow{
  
    margin-top: 11%;
    
  }
  
  .pare{
    font-size: 18px;
    font-weight: 400;
    color: #193054;
    font-family: "Blinker", sans-serif;
    margin-top: 1rem;
  }
  .cpare{
    font-size: 18px;
    font-weight: 400;
    color: #ffff;
    font-family: "Blinker", sans-serif;
  }
  .phead {
    font-size: 62px;
    color: #193054;
    font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-style: normal;
}
  .pl_bottom{
    padding-top: 12%;
    text-align: center;
  }
  .ph{
    font-size: 58px;

  }
  .ppara1{
    font-size: 18px;
    color: #193054;
    font-style: normal;
    font-family: "Fira Sans", sans-serif;

  }
 .provideroverlay{
  background: linear-gradient(to bottom,#021125  , #406CAA  );
  width: 100%;
  height: 651px;
  border-radius: 7px;
  margin-top: 70px;
  margin-bottom: 10px;
 }
 .proverlay{
    width: 100%;
    height: 8rem;
    }
  .plcontent{
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif ;
    padding-top: 46px;
    width: 100%;
    height: 651px;
  }
  .logo {
    width: 24.33px;
    height: 24.33px;
    margin-right: 10px;
}
.clogo {
  width: 24.33px;
  height: 24.33px;
  margin-right: 5px;
}

/* Glass Effect */
.glass-effect {
  background: linear-gradient(180deg, rgba(207, 227, 255, 0.75) 0%, rgba(170, 205, 255, 0.75) 100%);
  backdrop-filter: blur(10px); /* Apply the blur effect for glassmorphism */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  transition: transform 0.3s ease;
  width: 300px; /* Fixed width */
  height: 400px; /* Fixed height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
}
.mglass-effect {
  background: linear-gradient(180deg, rgba(207, 227, 255, 0.75) 0%, rgba(170, 205, 255, 0.75) 100%);
  backdrop-filter: blur(10px); /* Apply the blur effect for glassmorphism */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  transition: transform 0.3s ease;
  height: 300px; /* Fixed height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
}

.glass-effect:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Container adjustments */
.plcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Columns for three cards per row */
.colp {
  flex: 0 0 calc(33.333% - 20px); /* Three columns per row */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensure all cards are the same size */
.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: none;
  overflow: hidden;
  border-radius: 10px;
  width: 100%; /* Make card fill its parent container */
  height: 100%; /* Make card fill its parent container */
}

.cards img {
  width: 80px;
  height: 80px;
}

.card-description {
  padding: 20px;
  font-family: "Fira Sans", sans-serif;
  color: #193054;
  flex-grow: 1; /* Make the description take up available space */
}

/* Adjust for the last row with two cards */
@media (max-width: 768px) {
  .colp {
    flex: 0 0 calc(50% - 10px); /* Two columns for smaller screens */
  }
  .provideroverlay{
    margin-top: 0;
  }
}

/* Center the last row if it has fewer than 3 cards */
.prow:last-child {
  justify-content: center; /* Center the last row */
}

  
  .row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-content {
    flex: 1;
  }
  .ppleft{
    margin-left: 22%;
    margin-top: 10%;
    width: 70%;
    line-height: 2;
    font-family: "Fira Sans", sans-serif;

  }
  .psleft{
    margin-left: 20%;
    max-width: 80%;
    height: auto;
    margin-top: 1%;
  }
  .wsleft{
    
    max-width: 75%;
    height: auto;
    margin-top: -3%;
    margin-left: 25%
  }
  .wheeleft:hover{
    transform: scale(1.1); /* Adjust the scaling factor as needed */

  }
  
    .ppdeploy {
      width: 33.79px;
      height: 55.64px;
  }

  .piright{
    max-width: 68%;
    height: auto;
    margin-top: 9%;
    margin-left: 5%;
  }
  .piright:hover{
    transform: scale(1.1);
  }
  .psright {
    max-width: 72%;
    height: auto;
    margin-top: -8%;
    margin-left: 12%;
    line-height: 2;
    color: #193054;
    font-family: "Fira Sans", sans-serif;

}
.wsright {
  max-width: 62%;
  height: auto;
  margin-top: -8%;
  margin-left: 20%;
  line-height: 2;
  color: #193054;
  font-family: "Fira Sans", sans-serif;

}
  .right-content {
    flex: 1;
  }
  /* Initial state: content is off-screen (right) */
.iright-content {
  opacity: 0; /* Initially hidden */
  transform: translateX(-100%); /* Move out of view to the left */
  transition: transform 0.8s ease-out, opacity 0.8s ease-out; /* Smooth transition */
}

/* When visible, bring content into view */
.iright-content.show {
  opacity: 1;
  transform: translateX(0); /* Move content back into place */
}

  
  .image {
    max-width: 100%;
    height: auto;
  }
  
  .ptruly{
    margin-top: 6%;
  }
  .sb{
    margin-top: 1.2em;
    margin-bottom: 6.5em;
  }

  @media (max-width: 768px) {
  .phead{
    font-size: 32px;
  }
  .plf{
    width: 90%;
    height: 165px;
    margin-left: 1rem;
  }
  .platform-header{
    min-height: 355px;
  }
  .ploutoverlay{
    min-height: 355px;
  }
  .card-description {
    padding: 19px; /* Adjust padding for mobile devices */
    font-size: 16px; /* Adjust font size for mobile devices */
    overflow-y: auto; /* Add overflow property for scroll if necessary */
    line-height: 1.6;
  }
  .colp {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    margin-bottom: 2rem;
}
.wsleft{
  margin-left: 0;
}
.iright-content {
  opacity: 1;
  transform: translateX(0);
}
.wsright{
  max-width: 85%;
  height: auto;
  line-height: 2.5;
  width: 91%;
  text-align: left;
  margin-top: 3%;
  margin-left: 10%;
}
.row-container {
  display: flex;
  flex-direction: column; /* Display as column on mobile */
  align-items: center; /* Center align content */
}
.rc{
  margin-bottom: 1rem;
  margin-top: 1rem;

}

.left-content, .right-content {
  width: 100%; /* Make both left and right content take full width */
  text-align: center; /* Center align text */
  margin-top: 1rem;}
.ppleft {
  margin-left: 22px;
  line-height: 2;
  width: 91%;                                                       
  text-align: left;
}
.ppara1{
  font-size: 18px;
}
.piright {
  max-width: 97%;
  height: auto;
  margin-top: 0;
  margin-left: 0;
}
.provideroverlay{
  width: 99%;
    height: 795px;
}
.sb{
  margin-bottom: .5rem;
}
.psleft {
  margin-left: 0;
  max-width: 85%;
  height: auto;
  margin-top: 6%;
}
.psright {
  max-width: 85%;
  height: auto;
  line-height: 2.5;
  width: 91%;
  text-align: left;
  margin-top: 3%;
  margin-left: 10%;
}
.ptruly {
  margin-top: -6%;
}
  }

