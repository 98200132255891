.teamview {
    width: 100%;
    height: 2840px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    margin-top: 1rem;
}
.cloud{
    width: 240px;
    height: 120px;
}
.cloud {
    position: absolute;
    animation: moveCloud linear infinite;
    background-repeat: no-repeat;
    background-size: contain;
}
.dp{
  width: 131%;

}
.lp{
  margin-left: -19px !important;
}
.k{
  margin-left: -1rem;
}
.kp{
  margin-left: -35px !important;
}
.vn{
  margin-left: -35px !important;
  width: 145%;
}

.msingle-line{
  width: 145%;
    margin-left: -14px;
}
.mteam{
  
    height: 8em;
    width: 7em;
}
.clouds2 {
    position: absolute;
    animation: moveCloud linear infinite;
    background-repeat: no-repeat;
    background-size: contain;
    width: 120px;
    height: 90px;
}
.team:hover{
  transform: scale(1.1); /* Adjust the scaling factor as needed */

}

.cloud1 {
    top: 100px;
    left: -200px;
    animation-duration: 20s; /* Faster animation */
}
.cloud12 {
    top: 200px;
    left: -200px;
    animation-duration: 30s; /* Faster animation */
}

.cloud2 {
    top: 772px; /* Adjusted position */
    left: -400px;
    animation-duration: 30s;
}

.cloud3 {
    top: 1262px; /* Adjusted position */
    left: -500px;
    animation-duration: 30s; /* Slower animation */
}
.cloud4 {
    top: 2200px; /* Adjusted position */
    left: -500px;
    animation-duration: 20s; /* Slower animation */
}

@keyframes moveCloud {
    0% {
        transform: translateX(-200px);
    }
    100% {
        transform: translateX(calc(100% + 1300px));
    }
}


    .paddingcard{
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;
    background-color: #ffff;
    box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.15);
    border-radius: 11PX;
    }
    .tmg{
        margin-top: 1rem ;
    }
    .txmg{
        padding-bottom: 66px ;
       
    }
    .blh{
      font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-size: 62px;
    font-style: normal;
    }
    .blhic{
      font-family: "Blinker", sans-serif;
    font-style: normal;
    }
    .blp{
      
        font-family: "Fira Sans", sans-serif !important;
        font-weight: 400;
        font-size: 19px;
        font-style: normal;
      }
      .svblp{
      
        font-family: "Fira Sans", sans-serif !important;
        font-weight: bold;
        font-size: 20px;
        font-style: normal;
        margin-left: 20px;
        color: #193054;
        }
  .amt{
    margin-top: -17px;
  }
  .mamt{
    margin-top: -17px;
    font-size: 15px;
  }
   .contribute {
    position: absolute;
    top: -100px; 
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.5s, top 0.5s;
  }
  
  .visible {
    top: 50%;
    opacity: 1;
  }
  
  .contribute-wrapper {
    position: relative;
    height: 200px; /* Adjust as needed */
    margin-top: 4rem;
    padding-left: 4rem;
  }

   .contribute{
    height: 540px;
    width: 95%  ;
   }

.contribute-1 {
  height: 298px;
    width: 94%;
    margin-top: -96px;
    animation: slideFromTop 1s ease forwards;
    padding-left: -26px;
  }
  
  /* For Contributers2 (bottom image) */
  .contribute-2 {
    height: 300px;
    width: 86%;
    margin-top: 113px;
    animation: slideFromBottom 1s ease forwards;
    padding-left: 0px;
    margin-left: -2rem;
  }
  
  @keyframes slideFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media only screen and (max-width: 768px) {
    /* .teamview{
      margin-top: -4rem;
    } */
    .blh{
      font-size: 32px;
    }
    .blp .mcenter {
      flex-basis: calc(50% - 20px) !important; /* Ensure each team member takes up 50% of the width with spacing */
      max-width: calc(50% - 20px) !important; /* Adjusted maximum width */
      margin-bottom: 20px; /* Add spacing between team members */
    }
    .mcenter {
      margin-left: 0.4rem;
  }
  .contribute-wrapper{
    padding-left: 0;
  }
  .teamview{
    height: 100%;
  }
  .contribute-1 {
    height: 107px;
    width: 114%;
    margin-top: -96px;
    animation: slideFromTop 1s ease forwards;
    padding-left: 0;
    margin-left: -20px;}
    .contribute-2 {
      height: 108px;
      width: 110%;
      margin-top: -10px;
      animation: slideFromBottom 1s ease forwards;
      padding-left: 0px;
      margin-left: -1.8rem;
  }
  .cloud2 {
    top: 914px;
    
}
.contribute-wrapper{
  margin-top: 0;
}
.gy{
  margin-left: 18px;
}
.mgy{
  margin-left: -1rem;
}
.mhf{
  margin-left: 5rem;
}
  }