.mcircle {
    width: 25px;
    height: 25px;
    background-color: transparent;
    border-radius: 50%;
    border-color: white;
    border: solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fl{
    margin-left: 21px;
    margin-top: 2rem;
  }
  .mc{
    margin-left: 225px !important;
    margin-top: -35px;    background-color: #263F82;
    color: white;
  }
  .mfi{
    width: 200px;
    height: 45px;
    margin-left: -31px;
    margin-top: -29px;
  }
  .mfm{
    padding-left: 1rem;
    margin-top: 1rem;
  }
  .mcp {
    font-size: 12px;
    /* padding-top: 167px; */
    /* margin-left: 20px; */
}
  .mcol{
    margin-left: 65px;
  }
  .mfp{
    margin-left: -30px;
    margin-top: 13px;  }
    .mfpp{
      margin-left: -30px;
      margin-top: 13px; 
      max-width: 120% !important;
     }
  .mfr{
    margin-left: 128px;
    margin-top: -18rem;
}
  
.mfc{
    margin-left: 255px;
    margin-top: -18rem;
}
.mft{
  /* margin-left: -34px; */
  width: auto !important;

}

.mfpr{
    /* margin-left: 80px;
    margin-top: -49px; */
    width: auto !important;

}
.mfco{
    /* margin-left: 175px;
    margin-top: -49px; */
    width: auto !important;
}

.mccp{
    font-size: 11px;
    padding-top: 13px;
    /* margin-left: 45px; */
}
