.rvdo{
    padding-left: 155px;
}


.rplay{
    background-color: #C9E0FF    ;
    width: 100%;
    height: 651px;
    border-radius: 7px;
    margin-top: 70px;
    margin-bottom: 10px;
}
.rcard{
  width: 24rem;
    border-radius: 10px;
    background-color: white;
    border: none;
}
.rfr{
  width: 24rem;
    margin-left: -1rem;
    margin-top: -2rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.rc{
    margin-bottom: 150px;

}
.image-container {
  width: 608px;
    height: 350px;
    overflow: hidden;
    border: 1px solid #ccc;
    margin-top: 17px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}

.image-container img {
  width: 100%;
  height: 370px;
}

.radio-box {
  border: 1px solid #0a0a0a;
  border-radius: 7px;
  padding: 35px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}
/* .rm{
      margin-top: -44px;

} */
.radio-box.checked {
  background: linear-gradient(to right, #193054  ,#154992 );
  color: white;
}

.radio-box label {
  display: block;
}


.rctc {
    padding-top: 130px;
    position: relative;
}

.rctc::before {
    content: '';
    position: absolute;
    top: 69px;
    left: 63px;
    width: 91%;
    height: 90%;
    /* border-radius: 8px; */
    background-color: rgb(137 131 209 / 50%);  
      backdrop-filter: blur(10px); /* Apply blur effect */
    z-index: 1;
}

.rctp {
    margin-left: 200px;
    position: relative; /* Ensure text content is on top of the overlay */
    z-index: 2; /* Ensure text content is on top of the overlay */
}

.rcth {
    margin-left: 200px;
    font-size: 62px;
    position: relative; /* Ensure text content is on top of the overlay */
    z-index: 2; /* Ensure text content is on top of the overlay */
}

.rctb {
    margin-left: 200px;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #193054 0%, #21457D 80%);
    border-radius: 5px;
    color: white;
    padding: 7px 5px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    position: relative; /* Ensure button is on top of the overlay */
    z-index: 2; /* Ensure button is on top of the overlay */
}

.rctbg {
    height: 500px;
    background-color: #B3DBFF;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
}



.image-button {
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  padding: 15px 5px;
  cursor: pointer;
  flex: 1 1;
  margin: 0 5px;
  text-align: center;
}

.image-button.active {
  background: linear-gradient(to right, #193054, #154992);
  color: white;
}

.image-container {
  width: 100%; /* Ensure the image container takes full width */
  height: auto; /* Adjust height as needed */
  overflow: hidden;
  margin-top: 17px;
}

.image-container img {
  width: 100%;
  height: auto; /* Allow height to adjust automatically */
}

.image-description {
  font-size: 14px;
  padding: 23px 15px;
  text-align: center;
  background-color: #0E4695;
  color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
