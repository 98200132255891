.dctc {
    padding-top: 40px;
    position: relative;
    color: #193054;
   
}

/* .dctc::before {
    content: '';
    position: absolute;
    top: 71px;
    left: 63px;
    width: 91%;
    height: 89%;
    border-radius: 8px;
    background-color: rgba(128, 128, 128, 0.5);
    backdrop-filter: blur(10px);
    z-index: 1;
} */
.dctp {
    position: relative; /* Ensure text content is on top of the overlay */
    z-index: 2; /* Ensure text content is on top of the overlay */
}

.dcth {
    font-size: 62px;
    font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-style: normal;
}


/* .dctbg {
    height: 500px;
    background-image: url('./dpg1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
} */
.doclay{
    padding: 8rem;
}