.cslide {
    width: 300px; /* Adjust width as needed */
    height: 500px; /* Adjust height as needed */
    background-color:#193054; /* Set background color */
    padding: 20px; /* Add padding for spacing */
    text-align: center; /* Center align content */
    border-radius: 1%;
  }
  .crm{
    max-width: 100%;
    max-height: 200px;
    margin-left: -15px;
    margin-top: -10px !important;

  }
  .cslide img {
    max-width: 100%;
    max-height: 200px;
    /* margin-left: -15px; */
    /* margin-top: 21px; */
  }
  
  /* Styling for active slide */
  .slick-current .cslide {
    width: 85% !important; /* Adjust width for active slide */
    display: inline-block; /* Ensure proper display */
    margin-left: 78px !important; /* Adjust margin for active slide */
  }
  .slideinside{
    text-align: left;
    margin-left: 1rem;
    margin-top: 20px;
    color: white;
}

.cntrp{
  margin-left: -382px;
    font-size: 18px;
    /* font-family: "Fira Sans", sans-serif; */
    font-family: "Blinker", sans-serif;
}
 
.cntrh{
  font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 62px;
}  

.cntrpp{
  font-size: 16px;
    font-family: "Fira Sans", sans-serif;
}

.cntrbtn{
  background-color: #fbfbfb;
    color: black;
    padding: 14px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Blinker", sans-serif;

}
.cntrbtn:hover{
  background: linear-gradient(to top, #DCE9FD , #C8DDFF );
  color: black;
  
  }
.cntrb{
  margin-top: -135px;
}
.cntric{
  margin-top: -20px;
    margin-left: 15px;
}

/* .crmpg{
  margin-top: 1rem!important;
} */

/* Add styles for previous and next slides (non-active slides) */
.slick-slide {
  transition: all 0.5s ease; /* Smooth transition for effects */
}

.slick-slide:not(.slick-current) .cslide {
  filter: brightness(0.5) blur(2px); /* Adjust brightness and blur */
  /* opacity: 0.7;  */
}

/* Styling for active slide to make it prominent */
.slick-current .cslide {
  filter: brightness(1) blur(0); /* Ensure no blur or brightness effect on the active slide */
  opacity: 1; /* Full opacity for active slide */
}
