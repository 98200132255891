.cookie-alert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f0f0f0; /* Adjust background color as needed */
    border-top: 1px solid #ccc; /* Add border for separation */
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Add shadow effect */
    z-index: 999; /* Ensure it's above other content */
  
    /* Animation */
    animation: slide-up 0.5s ease-out forwards;
  }
  
  .cookie-content {
    padding: 10px;
    background-color: #193054;
    color: white;
  }
  .ckbtn{
    border: 1px solid white;
    background-color: #193054;
    color: white;
    border-radius: 15px;
    padding: 7px;
    padding-left: 25px;
    margin-left: 30px;
    padding-right: 25px;
}

.pck {
  padding-left: 71px;
  padding-top: 13px;
}
.cic{
  margin-top: -5px;
}
  .bck{
    margin-top: 15px;
  }
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  @media (max-width: 768px) {
    .bck {
      margin-top: -7px !important;
      padding-left: -10px !important;
      margin-left: -8% !important;
    }
    .pck {
      padding-left: 20px !important;
      padding-top: 13px;
    }
  }
  



  