body {
    overflow-x: hidden;
    width: 100%;
}
.cursor {
    font-weight: 100;
    font-size: 4.5rem;
    color: black;
  }
  .ctpimg{
    width: 100%;
    object-fit: contain;
    /* border-radius: 2rem; */
}
  .rcti{
    margin-top: 6rem !important;
    margin-left: -2%;
  }
.ctbg {
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.rctbg1 {
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    /* width: 109%; */
    min-width: 100%;
}
.wctbg {
    height: 410px;
    width: 100%;
    background-color: #193054;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.pg{
    margin-top: 15px !important;
    padding-bottom: 40px !important;
  }
  

.ctc {
    padding-top: 8rem;
}
.pctc {
    padding-top: 2rem;
}
pbctc {
    padding-bottom: 2rem!important;
}

.ctpg {
    /* padding-top: 80px;
    padding-left: 50px; */
width: 80%;
padding: 1rem;
}
.pctpg {
    /* padding-top: 80px;
    padding-left: 50px; */
width: 80%;
padding: 1rem;
}
.rctpg {
    padding-top: 80px;
}


.btgit {
    background: linear-gradient(to right,#216CD6 , #79B1FF ); 
    color: white;
    border: 1px;
    border-radius: 4rem;
    padding: 15px;
}

.ctp {
    margin-left: 100px;
    font-family: "Fira Sans", sans-serif;
    font-style: normal;
}

.cth {
    margin-left: 100px;
    font-size: 62px;
    font-family: "Blinker", sans-serif;
    font-weight: 700;
    font-style: normal; }

.ctb {
    margin-left: 100px;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    background: linear-gradient(to left, #DCE9FD 0%, #C8DDFF 80%);
    border-radius: 4rem;
    color: #193054;
    padding: 15px 35px;
    border: none;
    font-family: "Fira Sans", sans-serif;
    font-style: normal;
    font-size: 16px;
    cursor: pointer;
}
.ctb:hover{
    background:  #193054;;
    color: #ffff;
}
.wtb {
    margin-left: 100px;
    align-items: center;
    justify-content: center;
    background-color: #4D86D6;
    border-radius: 5px;
    color: white;
    padding: 16px 30px;
    border: none;
    font-family: "Fira Sans", sans-serif;
    font-style: normal;
    font-size: 16px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .ctc{
        padding-top: 27px;
        margin-top: 1rem;
    }
    .cth{
        margin-left: 17px;
        font-size: 45px;
    }
    .ctp{
        margin-left: 17px;
    }
    .ctb{
        margin-left: 14px;
        margin-top: -2rem;
        padding: 14px 23px;

    }
    .ctpg {
        /* padding-left: 0rem !important; */
        width: 100%;
        margin-top: -2rem !important;
    }
    .pctpg {
        padding-left: 0rem !important;
        width: 100%;
        margin-top: 0 !important;
        margin-left: 8px;
    }
    .ctpg img{
        width: 100%;
        /* margin-left: 1rem; */
    }
    .dimg{
        margin-top: 2rem ;
        margin-left: 8px;
    }
    .ctbg {
        height: 630px;
    }
    .ctc{
        margin-left: 4%;
    }
    
    .gcontainer{
        height:50vh !important;
    }
    /* .pg{
        padding-top: 305px;

    } */
    .pctbg {
        height: 700px;
    }
    .mgb{
        width: 91% !important;
        padding: 5px !important;
        }
}


.gcontainer {
    /* Centering the content */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh; /* Full height to center vertically */
}

.glass-box {
    background: linear-gradient(180deg, rgba(207, 227, 255, 0.75) 0%, rgba(170, 205, 255, 0.75) 100%);
    border: 1px solid rgba(255, 255, 255, 0.3); /* Semi-transparent border for the glass effect */
    border-radius: 3rem; /* Rounded corners */
    backdrop-filter: blur(10px); /* Creates the frosted glass effect */
    padding: 40px; /* Padding inside the box */
    width: 80%; /* Width of the box */
    max-width: 600px; /* Maximum width */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
    overflow: hidden; /* Ensure content stays within the rounded corners */
    text-align: center;
}


