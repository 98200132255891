.use1{
    font-family: "Blinker", sans-serif !important;
    font-weight: 700;
    color: #193054;
    font-size: 50px;
 }

 .useimg{
    width: 100%;
 }
.us1{
   padding: 3rem !important;

}
.usevdo{
   padding-top: 3rem !important;

}

 @media (max-width: 768px) {
   .mcm{
      margin-top: -7%;
   }
   .useimg{
      margin-top: -1rem;
   }
   .usecc{
      padding: 1rem !important;
   }
   .usevdo{
      padding-top: 0 !important;
      padding-bottom: 2rem;
   margin-top: 0 !important;
   }
   .use1{
      font-size: 28px;
   }
   
.us1{
   padding: 0 !important;
   text-align: center;
}
.usep{
   text-align: left !important;
   line-height: 2;
 }

 }