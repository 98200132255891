.Fcolor {
    background-color: #0A142F;
    color: white;
  }
  
  .input-field {
    border: none ;
    border-bottom: 1px solid rgb(173, 171, 171);
    outline: none;
    background-color: #0A142F;
  }
  .bclr:hover{
    background: linear-gradient(to top, #DCE9FD , #C8DDFF );
    color: black;
    
    }
  .bclr {
    margin-left: 18px;
    background-color: #263F82;
    color: white;
  }
  .fmrgn{
    margin-left: 15px;
    margin-top: 47px;
  }
  .fmrg{
    font-family: "Fira Sans", sans-serif;
  }
  @media (min-width: 768px) {
    .fmrg {
      margin-left: 0rem !important;
    }
    
    
    
    .flw {
      margin-top: 115px;
      margin-left: 0;
    }
  }
 
  a{
    text-decoration: none;
  }

  .cp{
    font-size: 14px;
    padding-top: 30%;
  }

  .circle-icon {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    border-color: white;
    border: solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fimg{
    width:270px;
    height: 53px;
  }