.contact-header {
    min-height: 480px;
    width: 100%;
    font-family: alata;
    font-weight: 300;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .mcontact-header {
    width: 100%;
    font-family: alata;
    font-weight: 300;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .ch{
    font-size: 62px;
    font-weight: 700;
    font-style: normal;
  }
  .contact-overlay {
    min-height: 480px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 86px;
    color: #ffff;
  }

  .mcontact-overlay {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 86px;
    color: #ffff;
  }
/* .cnm{
  min-height: 230vh;
  background-color: #F3F8FF    ;

} */
  .cnlay{
    background-color: #F3F8FF    ;
    width: 100%;
    height: 1024px;
    border-radius: 7px;
    margin-top: 70px;
    margin-bottom: 10px;
}
.mcnlay{
  width: 100%;
  height: 1024px;
  border-radius: 7px;
  margin-bottom: 10px;
}
.mlocate {
  width: 100%;
  height: 342px;
  background-color: white;
  border-radius: 10px;
  /* margin-top: 30px; */
}
.mphone {
  /* width: 568px; */
  height: 340px;
  border-radius: 10px;
  margin-top: 3rem;
}
.loc {
    width: 20px;
    height: 40px;
}
.loc1{
  width: 30px;
    height: 30px;
    filter: invert(26%) sepia(29%) saturate(4616%) hue-rotate(186deg) brightness(35%) contrast(95%);
}
.loc2{
  width: 35px;
    height: 35px;
    filter: invert(26%) sepia(29%) saturate(4616%) hue-rotate(186deg) brightness(35%) contrast(95%);
}
.tw{
    margin-left: -42px !important;
}
.ytb{
    margin-left: -90px !important;
}
.locate{
    width: 568px;
    height: 342px;
    background-color: white;
    border-radius: 10px;
    margin-top: 30px;

}
.cform{
    width: 546px;
    height: 841px;
    background-color: white;
    border-radius: 10px;
    margin-top: 30px;

}
.mcform{
  height: 841px;
  background-color: white;
  border-radius: 10px;

}
.lc{
    margin-top: -103px;
}
.phone{
    width: 568px;
    height: 340px;
    border-radius: 10px;
    margin-top: 30px;
}

.custom-form {
    color: #989A9D  ;
  }
  
  .submit-button {
    float: right;
    margin-top: -30px;
    background: linear-gradient(to left,#193054 , #21457D );

  }
  .nm{
    margin-top: 34px !important;
  }
  .input{
    border: none ;
    border-bottom: 1px solid #dee2e6 !important;
    ;
    border-radius: 0;
    outline: none;
  }

  /* Styles for icon and text alignment */
.mlocate .row {
  display: flex;
  align-items: center; /* Vertically center-align icons and text */
}

.mlocate .col-md-6 {
  display: flex;
  align-items: center;
}

.mlocate .col-md-6 p {
  margin-left: 10px; /* Adjust this as needed */
}

/* Styles for the last row with icons only */
.mlocate .icon-row {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}

.mlocate .icon-row .col-md-2 {
  flex: 1; /* Ensure icons have equal spacing */
  text-align: center; /* Center each icon in its column */
}

.mlocate .row {
  display: flex;
  align-items: center; /* Center image and text vertically */
  padding-top: 1.2rem; /* Add padding between rows */
}

.mlocate .row img {
  margin-right: 10px; /* Adjust spacing between image and text */
}

.mlocate .row p {
  margin: 0; /* Remove any default margin from paragraphs */
}

/* .icon-row .circle-icon {
  font-size: 24px; 
} */
.cnm {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures children elements are spaced */
  min-height: 100vh; /* Ensures it covers full viewport */
  background-color: #F3F8FF;
}
/* Additional media query for better control */
@media (max-width: 768px) {
  .cnm {
    min-height: 120vh; /* More adaptive height for mobile */
  }

  .mcnlay {
    height: auto; /* Ensures content doesn't get cut off */
  }
}
.ccircle-icon {
  width: 25px;
  height: 25px;
  background-color: transparent;
  border-radius: 50%;
  border-color: white;
  border: solid 1px;
  display: flex;
  justify-content: center;
  filter: brightness(0) saturate(100%) invert(82%) sepia(21%) saturate(443%) hue-rotate(168deg) brightness(30%) contrast(95%);
  align-items: center;
}