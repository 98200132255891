.faq {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px;
  overflow-y: auto;
  width: 1020px;
  height: 534px;
  background-color: #B8D5FE;
  border-radius: 10px;
  margin: 75px auto 300px auto; /* Center the FAQ box */
}

  
  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
  }
  
  .toggle-icon {
    font-weight: bold;
    font-size: 24px
  }
  
  .answer {
    padding-top: 25px  }
  

    @media (max-width: 1390px) {
      .faq {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 50px;
        overflow-y: auto;
        width: 1020px;
         height: 534px;
         background-Color: #B8D5FE;
         border-radius: 10px;
         margin-left:190px;
         margin-bottom: 300px;
         margin-top: 75px;
    
        }
      
    }
    @media only screen and (max-width: 768px) {
      .faq {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 3rem;
        overflow-y: auto;
        width: 100%;
        height: 100vh;
        background-Color: #B8D5FE;
        border-radius: 10px;
        margin-left: 0;
        margin-bottom: 3rem;
        margin-top: 1rem;
    }
    .toggle-icon {
      font-weight: bold;
      font-size: 24px;
      margin-top: -2rem;
  }
  .fap1{
    text-align: center;
  }
  .faq-item{
    margin-top: 2rem;
  }
    }